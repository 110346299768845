import axios from 'axios';
import { getAuth, removeAuth, removeRT } from './identity.service';
import { checkFor401Error, checkForTokenExpiredError } from "../lib/utils";
import { refreshTokenAndSetAuth } from './user.service';

export const postWithAuth = (url, entity) => {
    const auth = getAuth();
    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.id_token,
    };
    return new Promise((resolve, reject) => {
        axios.post(url, entity, { headers }).then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data, statusCode: response?.status });
            }
        }).catch((ex) => {
            if (checkForTokenExpiredError(ex)) {
                const callback = () => postWithAuth(url, entity);
                refreshTokenAndSetAuth(callback).then((data) => resolve(data));
                return;
            }
            resolve({ status: false, message: ex.message, response: ex.response?.data, statusCode: ex?.response?.status });
        })
    })
}

export const postWithoutAuth = (url, entity) => {
    return new Promise((resolve, reject) => {
        axios.post(url, entity).then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data, statusCode: response?.status });
            }
        }).catch(ex => {
            resolve({ status: false, message: ex.message, errorResponse: ex?.response?.data, statusCode: ex?.response?.status });
        })
    })
}
export const getWithAuth = (url, token = '', diseaseId, auth) => {
    const authData = auth ?? getAuth();
    const headers = {
        'content-type': 'application/json',
        'x-access-token': authData?.id_token,
        ...(diseaseId ? { 'disease-id': diseaseId } : {})
    };
    return new Promise((resolve, reject) => {
        axios.get(url, { headers }).then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data, statusCode: response?.status });
            }
        }).catch(ex => {
            if (checkForTokenExpiredError(ex)) {
                const callback = () => getWithAuth(url);
                refreshTokenAndSetAuth(callback).then((data) => resolve(data));
                return;
            }
            resolve({ status: false, message: ex.message, statusCode: ex?.response?.status });
        })
    })
}

export const putWithOutAuth = (url, data) => {
    const auth = getAuth();
    return new Promise((resolve, _reject) => {
        axios
            .put(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data, statusCode: response?.status });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message, statusCode: ex?.response?.status });
            });
    });
};

export const putWithAuth = (url, data) => {
    const auth = getAuth();
    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.id_token,
    };
    return new Promise((resolve, _reject) => {
        axios
            .put(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data, statusCode: response?.status });
                }
            })
            .catch((ex) => {
                if (checkForTokenExpiredError(ex)) {
                    const callback = () => putWithAuth(url, data);
                    refreshTokenAndSetAuth(callback).then((data) => resolve(data));
                    return;
                }
                resolve({ status: false, message: ex.message, response: ex.response?.data, statusCode: ex?.response?.status });
            });
    });
};

export const patchWithOutAuth = (url, data) => {
    const headers = {
        "content-type": "application/json",
    };
    return new Promise((resolve, _reject) => {
        axios
            .patch(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data, statusCode: response?.status });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message, statusCode: ex?.response?.status });
            });
    });
};

export const patchWithAuth = (url, data) => {
    const auth = getAuth();
    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.id_token,
    };
    return new Promise((resolve, _reject) => {
        axios
            .patch(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data, statusCode: response?.status });
                }
            })
            .catch((ex) => {
                if (checkForTokenExpiredError(ex)) {
                    const callback = () => patchWithAuth(url, data);
                    refreshTokenAndSetAuth(callback).then((data) => resolve(data));
                    return;
                }
                resolve({ status: false, message: ex.message, statusCode: ex?.response?.status });
            });
    });
};

export const deleteWithAuth = (url, diseaseId) => {
    const auth = getAuth();
    const headers = {
        'content-type': 'application/json',
        'x-access-token': auth?.id_token,
        ...(diseaseId ? { 'disease-id': diseaseId } : {})
    };
    return new Promise((resolve, reject) => {
        axios.delete(url, { headers }).then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data, statusCode: response?.status });
            }
        }).catch(ex => {
            if (checkForTokenExpiredError(ex)) {
                const callback = () => getWithAuth(url);
                refreshTokenAndSetAuth(callback).then((data) => resolve(data));
                return;
            }
            resolve({ status: false, message: ex.message, statusCode: ex?.response?.status });
        })
    })
}
